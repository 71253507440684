const getAllSchools = (state) => {
    let schools = state.schools.list;
    // Filter Coding subjects only
    schools = schools.filter(school => school.subject === 'Coding');
    // Sort by start date
    schools.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
    return schools;
}

const getStudentsBySchoolId = (state, schoolId) => {
    const school = state.schools.list.find(school => school.id === schoolId);
    return school ? school.students : [];
}

export {
    getAllSchools,
    getStudentsBySchoolId,
};