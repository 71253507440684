// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n.common_onboarding-container_3qCjH {\n  width: 100%;\n  min-height: 100vh;\n  padding-bottom: 2rem;\n  background: #bce3ff;\n}\n\n.common_onboarding-header_FeJ42 {\n  display: block;\n  padding-top: 2rem;\n  padding-bottom: 4rem;\n  margin: 0;\n  text-align: center;\n  font-size: 2rem;\n  color: #333;\n}\n\n.common_onboarding-list_3NpbH {\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  grid-gap: 10%;\n  row-gap: 56px;\n  justify-items: center;\n  padding: 0 10%;\n  margin: 0;\n  list-style: none;\n}\n\n.common_onboarding-card_2Do8P {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  background: #0b99ff;\n  border-radius: 10px;\n  transition: background 0.3s;\n\n  &:hover {\n    background: #098cea;\n  }\n}\n\n.common_onboarding-card-link_tUEON {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  gap: 4px;\n  width: 100%;\n  padding: 1.2rem 1rem;\n  text-align: center;\n  text-decoration: none;\n  font-size: 1.5rem;\n  color: #fff;\n  cursor: pointer;\n}\n\n.common_onboarding-card-link_tUEON p {\n  margin: 0;\n  font-size: inherit;\n}\n\n.common_onboarding-loading-message_2r_dc {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 200px;\n  font-size: 2rem;\n  color: #333;\n}", ""]);
// Exports
exports.locals = {
	"onboarding-container": "common_onboarding-container_3qCjH",
	"onboardingContainer": "common_onboarding-container_3qCjH",
	"onboarding-header": "common_onboarding-header_FeJ42",
	"onboardingHeader": "common_onboarding-header_FeJ42",
	"onboarding-list": "common_onboarding-list_3NpbH",
	"onboardingList": "common_onboarding-list_3NpbH",
	"onboarding-card": "common_onboarding-card_2Do8P",
	"onboardingCard": "common_onboarding-card_2Do8P",
	"onboarding-card-link": "common_onboarding-card-link_tUEON",
	"onboardingCardLink": "common_onboarding-card-link_tUEON",
	"onboarding-loading-message": "common_onboarding-loading-message_2r_dc",
	"onboardingLoadingMessage": "common_onboarding-loading-message_2r_dc"
};
module.exports = exports;
