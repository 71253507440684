import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const { format } = require('date-fns');

import { getAllSchools } from '../../store/selectors/schools';

import commonStyles from '../common.css';

function SchoolsListScreen({
    schools,
}) {
  return (
    <div className={commonStyles.onboardingContainer}>
      <h1 className={commonStyles.onboardingHeader}>Select your school</h1>

      {schools.length === 0 && (
        <p className={commonStyles.onboardingLoadingMessage}>Loading...</p>
      )}
      
      <ul className={commonStyles.onboardingList}>
        {schools.map(school => (
          <li className={commonStyles.onboardingCard} key={school.id}>
            <Link className={commonStyles.onboardingCardLink} to={`/start/schools/${school.id}`}>
              <p>{school.name}</p>
              <p>{format(new Date(school.startDate), 'MMM d, yyyy')}</p>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}


const mapStateToProps = state => ({
  schools: getAllSchools(state)
});

export default connect(
  mapStateToProps
)(SchoolsListScreen);
