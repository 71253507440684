import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { addSchools } from './actions/schools';

import { store, persistor } from './index.jsx';
import { ProviderContext } from './context';
import SubscriptionsHOCComponent from './subscriptions';

import { getSchools } from '../api/schools.jsx';

export default ({children}) => {
  const { dispatch } = store;

  const fetchSchools = async () => {
    const schools = await getSchools();
    dispatch(addSchools(schools));
  }

  useEffect(() => {
    fetchSchools();
  }, []);

  return (
    <Provider context={ProviderContext} store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SubscriptionsHOCComponent context={ProviderContext}>
          {children}
        </SubscriptionsHOCComponent>
      </PersistGate>
    </Provider>
  );
}
