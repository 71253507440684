import endpoints from '../config/api';

function sanitizeString(name) {
  return name.toLowerCase().replace(/\s/g, '-').replace(/[^a-zA-Z0-9\s]/g, '');
}

function generateId(name, subject, sessionId, startDateTimestamp) {
  return `${sanitizeString(name)}${subject ? `-${sanitizeString(subject)}` : ''}-${sanitizeString(sessionId)}-${startDateTimestamp}`;
}

function normalizeStudents(students, sessionId) {
  return [
    ...(students.map(student => ({
      id: student.Id,
      name: student.Name,
      storageKey: student.Id, // This key is used to store the student's data in the server
      sessionId: sessionId,
    }))),
    {
      id: 'guest',
      name: 'Guest Student',
      storageKey: 'guest',
      sessionId: sessionId,
    }
  ];
}

function normalizeSchools(schools) {
  return schools.map(school => {
    const startDateTimestamp = new Date(school.ClassStart).getTime();
    const sessionId = school.SessionId.replace('.', '_');
    const id = generateId(school.School, school.Subject, sessionId, startDateTimestamp);
    return {
      id,
      sessionId: sessionId,
      name: school.School,
      subject: school.Subject,
      startDate: startDateTimestamp,
      endDate: new Date(school.ClassEnd).getTime(),
      students: normalizeStudents(school.Students, sessionId),
    };
});
}

async function getSchools() {
  const response = await fetch(endpoints.get_schools, {
    mode: 'cors',
  });
  if (!response.ok) {
    console.error(`Failed to fetch schools: ${response.status}`);
    return;
  }
  const schools = await response.json();
  return normalizeSchools(schools);
}

export {
    getSchools,
};