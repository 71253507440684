import React from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import { getStudentsBySchoolId } from '../../store/selectors/schools';
import { setCurrentStudent } from '../../store/actions/students';
import { ProviderContext } from '../../store/context';

import commonStyles from '../common.css';

export default function WrappedStudentsListScreen() {
  const { schoolId } = useParams();
  return (
    <StudentsListScreen schoolId={schoolId} context={ProviderContext} />
  );
}

function StudentsListScreenComponent({
    students,
    dispatch,
}) {
  const navigate = useNavigate();

  function handleClick(e, studentId) {
    e.preventDefault();
    const student = students.find(student => student.id === studentId);
    dispatch(setCurrentStudent(student));
    navigate('/');
  }

  return (
    <div className={commonStyles.onboardingContainer}>
      <h1 className={commonStyles.onboardingHeader}>Select Student</h1>

      {students.length === 0 && (
        <p className={commonStyles.onboardingLoadingMessage}>Loading...</p>
      )}

      {students.length > 0 && (
        <ul className={commonStyles.onboardingList}>
        {students.map(student => (
          <li className={commonStyles.onboardingCard} key={student.id}>
            <a className={commonStyles.onboardingCardLink} onClick={(e) => handleClick(e, student.id)}>
              {student.name}
            </a>
          </li>
          ))}
        </ul>
      )}
    </div>
  );
}

const mapStateToProps = (state, props) => {
  const { schoolId } = props;

  return {
    students: getStudentsBySchoolId(state, schoolId),
  };
};

const StudentsListScreen = connect(
  mapStateToProps
)(StudentsListScreenComponent);