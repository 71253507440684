const getInjectedProjectKey = (state) => {
  // Get it from the URL as a query parameter
  const urlParams = new URLSearchParams(window.location.search);
  const projectKey = urlParams.get('projectKey') || window.localStorage.getItem('injectedProjectKey');
  if (projectKey === 'reset') {
    window.localStorage.removeItem('injectedProjectKey');
    return null;
  }

  if (projectKey) {
    // Save it to localStorage so it persists across page reloads
    window.localStorage.setItem('injectedProjectKey', projectKey);

    // Remove it from the URL so it doesn't get passed to other pages
    const url = new URL(window.location);
    url.searchParams.delete('projectKey');
    window.history.replaceState({}, '', url);
  }

  return projectKey;
}

export {
  getInjectedProjectKey
};