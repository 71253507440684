import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { 
  getCurrentStudent,
  getCurrentStudentStorage,
} from '../selectors/students.jsx';

import { 
  loadUnlockedSprites,
  loadUnlockedBackdrops,
} from '../actions/students.jsx';

import { saveFile, fetchFile } from '../../api/files';

// Save and load student storage to the server
function SubscriptionsHOCComponent({
  children,
  currentStudent,
  currentStudentStorage,
  dispatch,
}) {

  const [loaded, setLoaded] = useState(false);

  async function saveStudentStorage() {
    try {
      const fileName = `${currentStudent.storageKey}/store.json`;
      await saveFile(fileName, JSON.stringify(currentStudentStorage)); 
    } catch (error) {
      console.error('Failed to save student storage: ', error);
    }
  }

  async function loadStudentStorage() {
    try {
      const fileName = `${currentStudent.storageKey}/store.json`;
      const response = await fetchFile(fileName);
      if (!response) {
        console.error('Failed to load student storage');
        setLoaded(true);
        return;
      }
      const content = await response.json();
      if (content.unlocked_sprites) {
        dispatch(loadUnlockedSprites(content.unlocked_sprites));
      }

      if (content.unlocked_backdrops) {
        dispatch(loadUnlockedBackdrops(content.unlocked_backdrops));
      }
    } catch (error) {
      console.error('Failed to load student storage: ', error);
    }

    setLoaded(true);
  }

  useEffect(() => {
    if (currentStudentStorage && loaded) {
      saveStudentStorage();
    }
  }, [currentStudentStorage, loaded]);

  useEffect(() => {
    if (currentStudent) {
      loadStudentStorage();
    }
  }, [currentStudent]);

  return (
    <>
      {children}
    </>
  );
}

const mapStateToProps = (state, props) => {
  return {
    currentStudent: getCurrentStudent(state),
    currentStudentStorage: getCurrentStudentStorage(state),
  };
};

const SubscriptionsHOC = connect(
  mapStateToProps
)(SubscriptionsHOCComponent);

export default SubscriptionsHOC;