// Polyfills
import 'es6-object-assign/auto';
import 'core-js/fn/array/includes';
import 'core-js/fn/promise/finally';
import 'intl'; // For Safari 9

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';

import {
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider,
    Route,
    Navigate,
    useNavigate
  } from 'react-router-dom';

import { clearCurrentStudent } from '../onboarding/store/actions/students';
import { getCurrentStudent } from '../onboarding/store/selectors/students';
import { getInjectedProjectKey } from '../onboarding/store/selectors/files';
import { ProviderContext } from '../onboarding/store/context';

import AppStateHOC from '../lib/app-state-hoc.jsx';
import BrowserModalComponent from '../components/browser-modal/browser-modal.jsx';
import supportedBrowser from '../lib/supported-browser';
import { GUIApp } from './render-gui.jsx';

import OnboardingStoreProvider from '../onboarding/store/provider.jsx';
import StudentsListScreen from '../onboarding/screens/StudentsList/index.jsx';
import SchoolsListScreen from '../onboarding/screens/SchoolsList/index.jsx';

import styles from './index.css';

const defaultRoute = '/start/schools';

const NoMatch = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(defaultRoute);
  }, []);

  return null;
}

const ProtectedRoute = (() => {
  const ProtectedRouteComponent = ({
    children,
    dispatch,
    currentStudent,
    injectedProjectKey
  }) => {
    // If session started more than 3h ago, clear the current student
    if (!injectedProjectKey && currentStudent && (!currentStudent.sessionStartedOn || (currentStudent.sessionStartedOn && ((Date.now() - currentStudent.sessionStartedOn) > 3 * 60 * 60 * 1000)))) {
      console.warn('Clearing current student due to session timeout (3h)');
      dispatch(clearCurrentStudent());
      return <Navigate to={defaultRoute} replace />;
    }

    if (!injectedProjectKey && !currentStudent) {
      return <Navigate to={defaultRoute} replace />;
    }

    return children;
  };

  const mapStateToProps = (state) => {  
    return {
      currentStudent: getCurrentStudent(state),
      injectedProjectKey: getInjectedProjectKey(state),
    };
  };

  return connect(
    mapStateToProps
  )(ProtectedRouteComponent);
})();

const appTarget = document.createElement('div');
appTarget.className = styles.app;
document.body.appendChild(appTarget);

if (supportedBrowser()) {
  // require needed here to avoid importing unsupported browser-crashing code
  // at the top level
  // require('./render-gui.jsx').default(appTarget);

  const router = createBrowserRouter(
      createRoutesFromElements([
          <Route path="/start/">
              <Route path="schools/:schoolId" element={<StudentsListScreen context={ProviderContext} />} />
              <Route path="*" element={<SchoolsListScreen context={ProviderContext} />} />
          </Route>,
          <Route path="/" element={
            <ProtectedRoute context={ProviderContext}>
              <GUIApp appTarget={appTarget} />
            </ProtectedRoute>
          } />,
          <Route path="*" element={<NoMatch />} />
      ])
  );

  ReactDOM.render(
      <OnboardingStoreProvider>
          <RouterProvider router={router} />
      </OnboardingStoreProvider>
  , appTarget);

} else {
    BrowserModalComponent.setAppElement(appTarget);
    const WrappedBrowserModalComponent = AppStateHOC(BrowserModalComponent, true /* localesOnly */);
    const handleBack = () => {};
    // eslint-disable-next-line react/jsx-no-bind
    ReactDOM.render(<WrappedBrowserModalComponent onBack={handleBack} />, appTarget);
}
